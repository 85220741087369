import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1360px;
  padding: 0 40px;

  @media (max-width: 680px) {
    padding: 0 20px;
  }
`;
