import styled from "styled-components";

export const WinnersContainer = styled.section`
  padding: 50px 0 100px 0;
`;
export const WinnerItem = styled.article`
  margin-bottom: 30px;
  font-size: 20px;

  .name {
    font-size: 24px;
    font-weight: bold;
    color: #0096a5;
    line-height: 2em;
  }
`;
