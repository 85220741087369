import React from "react";
import * as S from "./styled";
import IonIcon from "@reacticons/ionicons";

interface ModalProps {
  children: React.ReactNode;
  isVisible: boolean;
  closeModal: () => void;
}

const Modal: React.FC<ModalProps> = ({ children, isVisible, closeModal }) => {
  React.useEffect(() => {
    document.body.style.overflowY = isVisible ? "hidden" : "scroll";
  }, [isVisible]);

  return (
    isVisible && (
      <S.Modal style={{ top: window.scrollY }}>
        <div className="container">
          {children}
          <IonIcon onClick={closeModal} size="large" name={"close-circle-outline"} className="close-btn" />
        </div>
        <div className="close-area" onClick={() => closeModal()} />
      </S.Modal>
    )
  );
};

export default Modal;
