import styled from "styled-components";
import { colors } from "../../constants/colors";
import { borderRadius } from "../../constants/values";

export const Modal = styled.div`
  position: absolute;
  background-color: ${colors.black}70;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  .close-area {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .container {
    position: relative;
    background: ${colors.white};
    border-radius: ${borderRadius.big};
    padding: 70px 50px;
    z-index: 12;
    max-height: 800px;
    overflow: scroll;
    height: 95vh;
    min-height: 400px;
    margin: 20px 0;

    .close-btn {
      right: 30px;
      top: 30px;
      position: absolute;
      cursor: pointer;
      color: ${colors.black}50;
      transition: color 0.3s;

      &:hover {
        color: ${colors.black};
      }
    }
  }
  @media (max-width: 880px) {
    .container {
      height: calc(100vh - 40px);
      max-height: none;
      width: 100%;
      margin: 0 20px;
      padding: 60px 20px;
      border-radius: 10px;
    }
  }
`;
