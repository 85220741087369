import styled from "styled-components";
import { sizes, font } from "../../constants/fonts";

export const Reglament = styled.section`
  text-align: justify;
  .main-ol {
    & > li {
      margin-bottom: 50px;
    }
  }

  .second-ol > li {
    margin-bottom: 10px;
  }

  .third-ol > li {
    margin-top: 10px;
  }

  ol {
    list-style: none;

    & li {
      ol > li {
        margin-left: 20px;
      }

      &:last-child {
        /* margin-bottom: 50px; */
      }
    }

    .main-li {
      font-size: ${sizes.title};
      font-family: ${font.bold};
      margin-bottom: 20px;
    }
  }
`;
