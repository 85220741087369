import styled from "styled-components";
import { colors } from "../../constants/colors";
import { sizes, font } from "../../constants/fonts";

export const Header = styled.header`
  .logo-container {
    display: flex;
    justify-content: center;
    img {
      width: 300px;
    }
  }

  h1 {
    color: ${colors.primary};
    font-size: ${sizes.bitTitle};
    font-family: ${font.bold};
    margin-top: 30px;
    margin-bottom: 30px;

    span {
      color: ${colors.secondary};
    }
  }

  .block {
    max-width: 60%;
    span {
      color: ${colors.primary}70;
      font-size: ${sizes.middle};
      font-family: ${font.semibold};
      text-transform: uppercase;
    }
  }
`;
