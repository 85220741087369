import React from "react";
import { toast } from "react-toastify";

import Modal from "../Modal";
import { Button } from "../../components/Button";
import * as S from "./styled";
import IonIcon from "@reacticons/ionicons";
import { colors } from "../../constants/colors";

import Doc_1 from "../../assets/docs/Согласие на обработку персональных данных для распространения.docx";
import Doc_2 from "../../assets/docs/Согласие на обработку персональных данных.docx";
import DOC_3 from "../../assets/docs/Согласие на распространение работ.docx";
import DOC_4 from "../../assets/docs/Согласие на участие в Конкурсе.docx";
import DOC_5 from "../../assets/docs/Согласие с положением о конкурсе.docx";

const DOCS = ["Заявка", "Копия страниц зачетной книжки", "Резюме", "Портфолио"];
const TEACHER_DOCS = ["Заявка", "Портфолио"];
const TYPES = ["Стажер-исследователь", "Научный руководитель"];

const RequestModal = ({ isVisible, closeModal }) => {
  const [user, setUser] = React.useState({ firstName: "", secondName: "", lastName: "" });
  const [course, setCourse] = React.useState("");
  const [university, setUniversity] = React.useState("");
  const [job, setJob] = React.useState("");
  const [rank, setRank] = React.useState("");

  const [activeTab, setActiveTab] = React.useState<0 | 1>(0);
  const [files, setFiles] = React.useState([...Array(DOCS.length)]);

  const [checkboxes, setCheckboxes] = React.useState([false, false, false, false, false]);
  const [isLoading, setIsLoading] = React.useState(false);

  const successNotify = () => toast("Заявка успешно отправлена", { type: "success" });
  const errorNotify = () => toast("Произошла ошибка, попробуйте снова!", { type: "error" });

  React.useEffect(() => {
    resetData();
  }, [activeTab]);

  const resetData = () => {
    setUser({ firstName: "", secondName: "", lastName: "" });
    setJob("");
    setRank("");
    setCourse("");
    setUniversity("");
    setFiles([...Array(activeTab === 0 ? DOCS.length : TEACHER_DOCS.length)]);
    setCheckboxes([false, false, false, false, false]);
    setIsLoading(false);
  };

  const sendRequest = () => {
    const formData = new FormData();
    setIsLoading(true);

    files.map((file, index) => file && formData.append(`files`, file));
    formData.append("typeNum", String(activeTab));
    formData.append("type", TYPES[activeTab]);
    formData.append("fio", `${user.firstName} ${user.secondName} ${user.lastName}`);
    formData.append("university", university);

    if (activeTab === 0) {
      formData.append("course", course);
    } else {
      formData.append("job", job);
      formData.append("rank", rank);
    }

    fetch("https://api.stagerium.ru/", {
      method: "POST",
      body: formData,
    })
      .then(({ status }) => {
        if (status === 200) {
          successNotify();
        } else {
          errorNotify();
        }
      })
      .catch(() => errorNotify())
      .finally(() => {
        resetData();
        closeModal();
      })
      .finally(() => setIsLoading(false));
  };

  const condition = React.useMemo(() => {
    const common =
      checkboxes.every((item) => item) &&
      user.firstName &&
      user.secondName &&
      user.lastName &&
      university &&
      files.every((item) => item);

    return activeTab === 0 ? course && common : common && job && rank;
  }, [user, course, university, checkboxes, files, activeTab, job, rank]);

  return (
    <Modal closeModal={closeModal} isVisible={isVisible}>
      <h2 className="title">Отправка заявки</h2>
      <S.TabsContainer>
        <S.Tabs>
          <S.Tab className={activeTab === 0 ? "active" : ""} onClick={() => setActiveTab(0)}>
            Стажер-исследователь
          </S.Tab>
          <S.Tab className={activeTab === 1 ? "active" : ""} onClick={() => setActiveTab(1)}>
            Научный руководитель
          </S.Tab>
        </S.Tabs>
      </S.TabsContainer>
      <S.Form>
        <S.FlexContainer>
          <S.Input
            value={user.firstName}
            onChange={({ target }) => setUser((prev) => ({ ...prev, firstName: target.value }))}
            type="text"
            placeholder="Фамилия"
          />
          <S.Input
            value={user.secondName}
            onChange={({ target }) => setUser((prev) => ({ ...prev, secondName: target.value }))}
            type="text"
            placeholder="Имя"
          />
          <S.Input
            value={user.lastName}
            onChange={({ target }) => setUser((prev) => ({ ...prev, lastName: target.value }))}
            type="text"
            placeholder="Отчество"
          />
        </S.FlexContainer>
        <S.FlexContainer>
          <S.Input
            value={university}
            onChange={({ target }) => setUniversity(target.value)}
            type="text"
            placeholder="Университет"
          />
          {activeTab === 0 && (
            <S.Input value={course} onChange={({ target }) => setCourse(target.value)} type="text" placeholder="Курс" />
          )}
          {activeTab === 1 && (
            <React.Fragment>
              <S.Input
                value={job}
                onChange={({ target }) => setJob(target.value)}
                type="text"
                placeholder="Должность"
              />
              <S.Input value={rank} onChange={({ target }) => setRank(target.value)} type="text" placeholder="Звание" />
            </React.Fragment>
          )}
        </S.FlexContainer>
        <br />
        <div className="files-container">
          <b>Прикрепите файлы:</b>
          <S.FlexContainer className="files">
            {(activeTab === 0 ? DOCS : TEACHER_DOCS).map((item, index) => (
              <label key={index}>
                <S.FileItem active={!!files[index]}>
                  <IonIcon name={"document-outline"} />
                  <p>{item}</p>
                  {files[index] && <IonIcon name={"checkmark-done-circle-outline"} className="done" size="large" />}
                </S.FileItem>
                <input
                  onChange={(e) =>
                    setFiles((prev) => prev.map((file, fileIndex) => (fileIndex === index ? e.target.files[0] : file)))
                  }
                  type="file"
                  multiple={false}
                  hidden
                />
              </label>
            ))}
          </S.FlexContainer>
        </div>
        {/* import Doc_1 from "../../assets/docs/Согласие на обработку персональных данных для распространения.docx";
import Doc_2 from "../../assets/docs/Согласие на обработку персональных данных.docx";
import DOC_3 from "../../assets/docs/Согласие на распространение работ.docx";
import DOC_4 from "../../assets/docs/Согласие на участие в Конкурсе.docx";
import DOC_5 from "../../assets/docs/Согласие с положением о конкурсе.docx"; */}
        <div className="agree">
          <input
            checked={checkboxes[0]}
            type="checkbox"
            onChange={({ target }) =>
              setCheckboxes((prev) => prev.map((bool, index) => (index === 0 ? target.checked : bool)))
            }
          />
          соглашаюсь на <a href={Doc_1}>обработку персональных данных для распространения</a>
        </div>
        <div className="agree">
          <input
            checked={checkboxes[1]}
            type="checkbox"
            onChange={({ target }) =>
              setCheckboxes((prev) => prev.map((bool, index) => (index === 1 ? target.checked : bool)))
            }
          />
          соглашаюсь на <a href={Doc_2}>обработку персональных данных</a>
        </div>
        <div className="agree">
          <input
            checked={checkboxes[2]}
            type="checkbox"
            onChange={({ target }) =>
              setCheckboxes((prev) => prev.map((bool, index) => (index === 2 ? target.checked : bool)))
            }
          />
          соглашаюсь на <a href={DOC_3}>распространение работа</a>
        </div>
        <div className="agree">
          <input
            checked={checkboxes[3]}
            type="checkbox"
            onChange={({ target }) =>
              setCheckboxes((prev) => prev.map((bool, index) => (index === 3 ? target.checked : bool)))
            }
          />
          соглашаюсь на <a href={DOC_4}>участие в коркурсе</a>
        </div>
        <div className="agree">
          <input
            checked={checkboxes[4]}
            type="checkbox"
            onChange={({ target }) =>
              setCheckboxes((prev) => prev.map((bool, index) => (index === 4 ? target.checked : bool)))
            }
          />
          соглашаюсь c <a href={DOC_5}>положением о конкурсе</a>
        </div>
        <S.FlexContainer>
          <Button color={colors.secondary} disabled={!condition || isLoading} onClick={sendRequest}>
            {!isLoading ? (
              "Отправить"
            ) : (
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
          </Button>
        </S.FlexContainer>
        <S.FlexContainer></S.FlexContainer>
      </S.Form>
    </Modal>
  );
};

export default RequestModal;
