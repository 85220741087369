import styled from "styled-components";
import { colors } from "../../constants/colors";
import { font } from "../../constants/fonts";

export const Form = styled.div`
  /* padding: 50px 0; */
  padding-top: 20px;

  .agree {
    margin-top: 5px;
    font-size: 14px;

    input {
      margin-right: 5px;
    }
  }

  button {
    margin-top: 40px;
    flex: 1;
  }

  a {
    color: ${colors.primary};
  }

  .files-container {
    margin: 30px 0;
  }
`;
export const FlexContainer = styled.div`
  display: flex;
  gap: 15px;

  &.files {
    margin-top: 20px;
    gap: 25px;
    margin-bottom: 20px;
  }

  @media (max-width: 880px) {
    flex-direction: column;
  }
`;

export const FileItem = styled.div<{ active: boolean }>`
  display: flex;
  border: ${({ active }) => (active ? `2px solid ${colors.secondary}` : `1px solid ${colors.black}20`)};
  padding: 10px 20px;
  border-radius: 10px;
  position: relative;

  p {
    margin-left: 5px;
  }

  .done {
    position: absolute;
    background-color: ${colors.white};
    right: -15px;
    top: -10px;
    z-index: 2;
    svg {
      color: ${colors.secondary};
    }
  }
`;

export const Input = styled.input`
  border-bottom: 2px solid ${colors.black}50;
  flex: 1;
  padding: 30px 10px 12px 10px;
  transition: border-color 0.5s;

  &:focus {
    border-bottom: 2px solid ${colors.primary};
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  margin-bottom: 20px;
`;

export const Tabs = styled.div`
  flex: 1;
  display: flex;
  background-color: ${colors.black}10;
  border-radius: 5px;
  height: 50px;
  align-items: center;
  overflow: hidden;

  @media (max-width: 600px) {
    flex-direction: column;
    height: 100px;
  }
`;

export const Tab = styled.div`
  color: ${colors.black}40;
  font-family: ${font.semibold};
  cursor: pointer;
  flex: 1;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  @media (max-width: 600px) {
    width: 100%;
  }

  &.active {
    background-color: ${colors.primary};
    color: ${colors.white};
    height: 100%;
    border-radius: 5px;
  }
`;
