import styled from "styled-components";
import { colors } from "../../constants/colors";
import { font, sizes } from "../../constants/fonts";

export const Footer = styled.div`
  background-color: ${colors.primary};
`;

export const FooterContent = styled.div`
  display: flex;
  padding: 30px;
  gap: 100px;
  color: ${colors.white};

  .logo {
    display: flex;
    align-items: flex-start;
    img {
      max-width: 100px;

      &.ci {
        margin-top: 15px;
      }
    }
  }

  @media (max-width: 950px) {
    gap: 50px;
  }

  @media (max-width: 680px) {
    .logo {
      order: 1;
    }

    flex-wrap: wrap;
  }

  p,
  a {
    opacity: 0.8;
  }

  .title-block {
    font-family: ${font.semibold};
    font-size: ${sizes.middle};
    margin-bottom: 15px;
    opacity: 1;
  }

  nav {
    flex: 1;
    display: flex;
  }

  ul {
    li {
      margin-bottom: 5px;
    }
  }

  a {
    color: ${colors.white};
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
    }
  }
`;
