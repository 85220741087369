import styled from "styled-components";
import { colors } from "../constants/colors";
import { borderRadius } from "../constants/values";
import { font } from "../constants/fonts";

export const Button = styled.button<{ color?: string }>`
  background-color: ${({ color }) => color ?? `${colors.primary}`};
  border-radius: ${borderRadius.small};
  padding: 15px 30px;
  color: ${colors.white};
  font-family: ${font.semibold};
  transition: opacity 0.3s;
  max-height: 51;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
