import React from "react";

import { Button } from "../../components/Button";
import * as S from "./styled";

import { Container } from "../../components/Container";

import GoalImage from "../../assets/img/goal.jpg";
import IntentImage from "../../assets/img/intent.jpg";
import TeacherImage from "../../assets/img/teacher.jpg";
import RequestModal from "../../components/RequestModal";

import Logo from "../../assets/img/stagerium_logo.png";

const Home = () => {
  const [modalIsVisible, setModalIsVisible] = React.useState(false);

  return (
    <Container>
      <S.InfoSection>
        <p className="desc">
          Конкурс направлен на интеграцию потенциала высших учебных заведений Ростовской области и органов публичной
          власти региона для оптимизации процесса предоставления услуг населению и бизнесу на основе клиентоцентричного
          подхода
        </p>
        <Button onClick={() => setModalIsVisible(true)}>Зарегистрироваться</Button>
      </S.InfoSection>
      <S.GoalSection>
        <div>
          <h2 className="title">Цель конкурса</h2>
          <p className="desc">
            Целью Конкурса является выявление и поддержка молодых исследователей для реализации ими творческого
            потенциала, а также практического применения профессиональных знаний, умений и навыков в системе органов
            публичной власти под руководством высококвалифицированных научно-педагогических кадров и наставников от
            органов власти
          </p>
        </div>
        <img src={GoalImage} alt="Цель" />
      </S.GoalSection>
      <S.MembersSection>
        <div>
          <h2 className="title">Участники конкурса</h2>
        </div>
        <S.Member>
          <img src={IntentImage} alt="" />
          <div>
            <h3>Стажер-исследователь </h3>
            <p className="desc">
              Обучающийся последних курсов по программам высшего образования в сфере государственного и муниципального
              управления или смежной с ней, обладающий определенными личностными и профессиональными компетенциями в
              области исследовательской и проектной деятельности.
            </p>
            <div className="requirements">
              <b className="desc">Требования:</b>
              <ul>
                <li>
                  1. Обучающийся последних курсов бакалавриата (3-4 курс) и специалитета (4-5 курс), магистратуры (1-2
                  курс)
                </li>
                <li>
                  2. Обучающийся по направлениям подготовки: государственное и муниципальное управление, менеджмент,
                  экономика, финансы, налоги и налогобложение, политология и др.
                </li>
                <li>3. Опыт исследовательской и проектной деятельности</li>
                <li>4. Средний учебный балл не менее 4,0</li>
                <li>
                  5. Личностные компетенции:
                  <ul>
                    <li>‒ склонность к исследовательской и проектной деятельности</li>
                    <li>
                      ‒ коммуникативные навыки, позволяющие научно аргументировать результаты исследования; участвовать
                      в дискуссиях и т.д.
                    </li>
                  </ul>
                </li>
                <li>
                  6. Профессиональные компетенции:
                  <ul>
                    <li>‒ знание основ исследовательской работы</li>
                    <li>‒ наличие навыков проектной работы</li>
                    <li>‒ способность выступать публично и презентовать полученные результаты.</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </S.Member>
        <S.Member className="reverse">
          <img src={TeacherImage} alt="" />
          <div>
            <h3>Научный руководитель </h3>
            <p className="desc">
              Научно-педагогический работник вуза, имеющий опыт педагогической деятельности и руководства
              научно-исследовательской работой студентов, сферой научных интересов которого является государственное и
              муниципальное управление.
            </p>
            <div className="requirements">
              <b className="desc">Требования:</b>
              <ul>
                <li>1. Наличие опыта проектной деятельности</li>
                <li>2. Наличие опыта преподавательской деятельности </li>
                <li>3. Наличие опыта руководства научно-исследовательской работой студентов</li>
                <li>
                  4. Профессиональные качества:
                  <ul>
                    <li>
                      - знание особенностей проведения научного исследования в соответствующей отрасли знаний и
                      методологии проектной деятельности;
                    </li>
                    <li>- умение оформлять проектные и исследовательские работы.</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </S.Member>
      </S.MembersSection>
      <S.StagesSection style={{ marginBottom: 50 }}>
        <h2 className="title">Этапы проведения конкурса</h2>
        <div className="stage-block">
          <div className="stage">1 этап</div>
          <div className="text-block">
            <p>Конкурсный отбор (до 5 ноября 2023 года)*</p>
            <ul>
              <li className="desc">• Прием и отбор конкурсных заявок (заочный этап)</li>
              <li className="desc">• Отборочная форсайт-сессия (очный этап только для студентов)</li>
            </ul>
          </div>
        </div>
        <div className="stage-block">
          <div className="stage">2 этап</div>
          <div className="text-block">
            <p>Работа над проектом (в течение 3-х месяцев)</p>
            <ul>
              <li className="desc">• Воркшоп «Методология подготовки проектных идей»</li>
              <li className="desc">
                • Вводные лекции «Основы внедрения клиентоцентричного подхода в органах публичной власти»
              </li>
              <li className="desc">• Стажировка в органах публичной власти</li>
              <li className="desc">• Поисковые исследования</li>
            </ul>
          </div>
        </div>
        <div className="stage-block">
          <div className="stage">3 этап</div>
          <div className="text-block">
            <p>Предварительная оценка проектов (через 2 месяца работы над проектом)</p>
            <ul>
              <li className="desc">• Экспертная сессия </li>
            </ul>
          </div>
        </div>
        <div className="stage-block">
          <div className="stage">4 этап</div>
          <div className="text-block">
            <p>Защита проектов (через 3 месяца работы над проектом) </p>
          </div>
        </div>
        <p className="desc">
          <i>*Организаторы Конкурса оставляют за собой право изменить сроки окончания приема заявок</i>
        </p>
        {/* <div className="table">
          <div className="table-content">
            <div className="row">
              <div className="caption">Этап</div>
              <div className="caption">Сроки проведения</div>
              <div className="caption">Описание</div>
            </div>
            <div className="row gray fist-stage">
              <div className="ceil">1 ЭТАП: Конкурсный отбор</div>
              <div className="ceil">
                <div className="row column gray">
                  <div className="ceil">До 20 октября 2023 года</div>
                  <div className="ceil">В течение недели после окончания приема заявок.</div>
                </div>
              </div>
              <div className="ceil">
                <div className="row column gray">
                  <div className="ceil">
                    <p>
                      <b>1 этап (заочный)</b> – прием и отбор конкурсных заявок.
                    </p>
                  </div>
                  <div className="ceil">
                    <p>
                      <b>2 этап (очный)</b> – отборочная форсайт-сессия.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row gray">
              <div className="ceil">2 ЭТАП: Работа над проектом</div>
              <div className="ceil">В течение 3х месяцев</div>
              <div className="ceil">
                Работа над проектом осуществляется в течение 3х месяцев в связке: стажер-исследователь, научный
                руководитель (трекер) и наставник. В рамках этого этапа предполагается проведение воркшопа «Методология
                подготовки проектных идей». (Основная задача – распределение участников проекта по тройкам (стажер,
                научный руководитель, наставник), совместное определение целей проекта, а также 3 вводные лекции.){" "}
              </div>
            </div>
            <div className="row gray">
              <div className="ceil">3 ЭТАП: Предварительная оценка проектов</div>
              <div className="ceil">Через 2 месяца работы над проектом</div>
              <div className="ceil">
                Проведение экспертной сессии по предварительной оценке и корректировке проектов с каждой проектной
                группой индивидуально.
              </div>
            </div>
            <div className="row gray">
              <div className="ceil">4 ЭТАП: Защита проектов</div>
              <div className="ceil">
                Через 3 месяца работы над проектом (будет приурочена ко Дню Российской науки (февраль))
              </div>
              <div className="ceil">
                В состав конкурсной комиссии, осуществляющей конкурсный отбор участников и оценку конкурсных работ,
                входят представители органов публичной власти, вузов и банка Центр-Инвест.
              </div>
            </div>
          </div>
        </div> */}
      </S.StagesSection>
      <S.WorkSection>
        <h2 className="title">Конкурсная работа (Проект)</h2>
        <p className="desc">
          <b>Конкурсная работа</b> должна быть выполнена в виде проекта, цель которого разработка нового решения
          предоставления услуг населению органами публичной власти на основе клиентоцентричного подхода с учетом лучших
          практик.
        </p>
        <br />
        <p className="desc">
          <b>Результатом</b> выполнения конкурсной работы должен стать проект нового решения предоставления услуг
          населению органами публичной власти на основе клиентоцентричного подхода с учетом лучших практик.
        </p>
      </S.WorkSection>
      <section style={{ marginBottom: 50 }}>
        <h2 className="title">Конкурсная комиссия</h2>
        <p className="desc justify">
          В состав конкурсной комиссии, осуществляющей конкурсный отбор участников и оценку конкурсных работ, входят
          представители органов публичной власти, вузов и коммерческого банка Центр-Инвест.
        </p>
      </section>
      <S.OrganizatorsSection>
        <h2 className="title">Организаторы конкурса</h2>
        <p className="desc">
          Организаторами Конкурса являются Южно-Российский институт управления – филиал федерального государственного
          бюджетного образовательного учреждения высшего образования «Российская академия народного хозяйства и
          государственной службы при Президенте Российской Федерации» и Публичное акционерное общество коммерческий банк
          «Центр-Инвест».
        </p>
        <p className="desc">
          Конкурс проводится в рамках реализации проекта{" "}
          <b>
            «Методика взаимодействия студентов вузов Ростовской области и органов публичной власти региона с целью
            прохождения стажировок и разработки инновационных проектов для решения проблем в деятельности органов
            власти»
          </b>{" "}
          на основании договора, заключенного между Южно-Российским институтом управления – филиалом федерального
          государственного бюджетного образовательного учреждения высшего образования «Российская академия народного
          хозяйства и государственной службы при Президенте Российской Федерации» и публичным акционерным обществом
          коммерческий банк «Центр-Инвест», а также соглашения о сотрудничестве между Правительством Ростовской области
          и федеральным государственным бюджетным образовательным учреждением высшего образования «Российская академия
          народного хозяйства и государственной службы при Президенте Российской Федерации»
        </p>
        <Button onClick={() => setModalIsVisible(true)}>Зарегистрироваться</Button>
      </S.OrganizatorsSection>

      <RequestModal isVisible={modalIsVisible} closeModal={() => setModalIsVisible(false)} />
    </Container>
  );
};

export default Home;
