import styled from "styled-components";
import { font } from "../../constants/fonts";
import { colors } from "../../constants/colors";

export const Navbar = styled.nav`
  .container {
    display: flex;
    justify-content: flex-end;
    position: relative;

    .menu-button {
      display: none;
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      padding: 20px;
      padding-right: 0px;
    }

    .logo {
      position: absolute;
      left: 40px;
      top: 50%;
      transform: translateY(-50%);
      display: grid;
      place-items: center;
      display: flex;

      .line {
        margin: 0 10px 0 5px;
        height: 30px;
        width: 1px;
        background: ${colors.primary}30;

        &:nth-child(4) {
          margin-left: 15px;
          margin-right: 0px;
        }
      }

      img {
        width: 100px;

        @media (max-width: 420px) {
          width: 80px;
        }
      }
    }

    ul {
      display: flex;
      list-style-type: none;
      gap: 30px;
      padding: 30px 0;

      @media (max-width: 1050px) {
        gap: 15px;
      }

      li {
        text-transform: uppercase;
        font-family: ${font.semibold};

        a {
          color: ${colors.primary}80;
          transition: color 0.3s;

          &:hover {
            color: ${colors.secondary};
          }

          &.active {
            color: ${colors.primary};
            font-family: ${font.bold};
          }
        }
      }
    }

    @media (max-width: 1200px) {
      justify-content: flex-end;
    }

    @media (max-width: 1000px) {
      justify-content: space-between;

      .menu-button,
      .logo {
        position: relative;
        left: auto;
        right: auto;
        transform: none;
      }

      .menu-button {
        display: block;
        position: relative;
        transform: none;
      }

      & > ul {
        display: none;
      }
    }
  }
`;

export const Drawer = styled.div`
  position: absolute;
  background: none;
  height: 100dvh;
  width: 100dvw;
  left: 0;
  top: 0;
  display: none;
  transition: left 0.3s, background 1s;

  &.active {
    display: block;
    left: 0;
    background: ${colors.black}90;
  }

  .wrapper {
    position: absolute;
    right: 0;
    top: 0;
    background: ${colors.primary};
    height: 100dvh;
    width: 80dvw;
    padding: 50px;
    padding-top: 0;
    z-index: 1000;

    svg {
      color: ${colors.white};
    }

    .menu-button {
      padding: 20px;
      padding-left: 0px;
    }

    ul {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      gap: 30px;
      padding: 30px 0;

      li {
        text-transform: uppercase;
        font-family: ${font.semibold};

        a {
          color: ${colors.white}80;
          transition: color 0.3s;

          &:hover {
            color: ${colors.white};
          }

          &.active {
            color: ${colors.white};
            font-family: ${font.bold};
          }
        }
      }
    }
  }
`;
