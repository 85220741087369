import styled from "styled-components";
import { colors } from "../../constants/colors";

export const CriteriaSection = styled.section`
  .row {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  .card {
    display: flex;
    padding: 10px 10px;
    background-color: ${colors.white};
    border-radius: 20px;
    box-shadow: 0px 0px 3px ${colors.black}30;
    align-items: center;

    &.f-1 {
      flex: 1;
    }

    &.f-2 {
      flex: 2;
    }

    &.f-3 {
      flex: 3;
    }

    img {
      max-width: 70px;
      margin-right: 10px;
    }
  }

  .grade {
    margin-left: 10px;
  }

  @media (max-width: 680px) {
    .row {
      flex-direction: column;
    }
  }
`;
