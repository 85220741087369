import React from "react";
import { Link } from "react-router-dom";
import * as S from "./styled";
import IonIcon from "@reacticons/ionicons";

import { Container } from "../Container";

import CILogo from "../../assets/img/CI_logo.png";
import AcademyLogo from "../../assets/img/academy_logo.png";

import Logo from "../../assets/img/stagerium_logo.png";

export const ROUTES = [
  {path: '/winners', name: 'Победители конкурса' },
  { path: "/", name: "О конкурсе" },
  { path: "/members", name: "Участники" },
  { path: "/documents", name: "Конкурсная документация" },
  { path: "/reglament", name: "Регламент" },
];

const Navbar = ({ location }: { location?: string }) => {
  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);

  return (
    <S.Navbar>
      <Container className="container">
        <div className="logo">
          <img src={AcademyLogo} alt="" />
          <div className="line"></div>
          <img src={CILogo} alt="" />
          <div className="line"></div>
          <img src={Logo} alt="" />
        </div>
        <ul>
          {ROUTES.map((route, index) => (
            <li key={index}>
              <Link className={route.path === location && "active"} to={route.path}>
                {route.name}
              </Link>
            </li>
          ))}
        </ul>
        <div className="menu-button" onClick={() => setDrawerIsOpen((prev) => !prev)}>
          <IonIcon size="large" name="menu-outline" />
        </div>
      </Container>
      <S.Drawer className={drawerIsOpen && "active"}>
        <div className="wrapper">
          <div className="menu-button" onClick={() => setDrawerIsOpen((prev) => !prev)}>
            <IonIcon size="large" name="menu-outline" />
          </div>

          <ul>
            {ROUTES.map((route, index) => (
              <li key={index}>
                <Link
                  onClick={() => setDrawerIsOpen(false)}
                  className={route.path === location && "active"}
                  to={route.path}>
                  {route.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </S.Drawer>
    </S.Navbar>
  );
};

export default Navbar;
