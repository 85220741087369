import Documents from "../pages/Documents";
import Home from "../pages/Home";
import Members from "../pages/Members";
import Reglament from "../pages/Reglament";
import Winners from "../pages/Winners";

export const router = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/winners",
    element: <Winners />,
  },
  {
    path: "/members",
    element: <Members />,
  },
  {
    path: "/documents",
    element: <Documents />,
  },
  {
    path: "/reglament",
    element: <Reglament />,
  },
];
