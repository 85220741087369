import styled from "styled-components";
import { colors } from "../../constants/colors";
import { font, sizes } from "../../constants/fonts";

export const Docs = styled.section`
  .button-container {
    padding-bottom: 50px;
  }

  .links-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 50px;
  }
  .link {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;

    svg {
      transform: scale(0.8);
    }

    a {
      color: ${colors.primary};
      text-decoration: underline;
      font-size: ${sizes.desc};
    }
  }
`;
