import React, { Fragment } from "react";
import { Container } from "../../components/Container";
import { content } from "../../constants/content";
import * as S from "./styled";

const Reglament = () => {
  return (
    <Container>
      <S.Reglament>
        <ol className="main-ol">
          {content.reglament.map((item, index) => (
            <li>
              <p className="main-li">
                {index + 1}. {item.name}
              </p>
              <ol className="second-ol">
                {item.items.map((listItem, indexListItem) => (
                  <li className="desc">
                    {typeof listItem === "object" && listItem.noPoint ? "" : `${index + 1}.${indexListItem + 1}. `}
                    {typeof listItem === "string" ? (
                      listItem
                    ) : (
                      <Fragment>
                        {listItem.text}
                        {listItem.items && (
                          <ol className="third-ol">
                            {listItem.items.map((lastItem, lastItemIndex) => (
                              <li>
                                {listItem.type === "disk"
                                  ? "• "
                                  : `${index + 1}.${indexListItem + 1}.${lastItemIndex + 1}. `}
                                {lastItem}
                              </li>
                            ))}
                          </ol>
                        )}
                      </Fragment>
                    )}
                  </li>
                ))}
              </ol>
            </li>
          ))}
        </ol>
        <i>*Организаторы Конкурса оставляют за собой право продлить сроки окончания приема заявок.</i>
      </S.Reglament>
    </Container>
  );
};

export default Reglament;
