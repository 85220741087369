export const content = {
  header: {
    title: "Конкурс Педагогического Мастерства",
    desc: "Регламент сезон 2023/2024",
  },
  goalSection: {
    name: "Цель Конкурса",
  },
  reglament: [
    {
      name: "Цель Конкурса",
      items: [
        "Целью проведения Конкурса «СТАЖЕРИУМ: РАЗВИВАЙ ТАЛАНТ ВО ВЛАСТИ» (далее - Конкурс) является выявление и поддержка молодых исследователей для реализации ими творческого потенциала, а также практического применения профессиональных знаний, умений и навыков в системе органов публичной власти под руководством высококвалифицированных научно-педагогических кадров и наставников от органов власти",
      ],
    },
    {
      name: "Содержание конкурса",
      items: [
        "Конкурс предполагает разработку нового решения предоставления услуг населению и бизнесу органами публичной власти на основе клиентоцентричного подхода с учетом лучших практик (далее конкурсная работа (проект)). ",
        "Стажер-исследователь выполняет конкурсную работу под руководством научного руководителя (трекера) и наставника от органа публичной власти на базе структурного подразделения органа публичной власти по заданному плану.",
        "Стажер-исследователь выполняет конкурсную работу посредством прохождения    стажировки в органах публичной власти.",
        "За каждым научным руководителем (трекером) закрепляется 2 стажера-исследователя.",
      ],
    },
    {
      name: "Участники Конкурса",
      items: [
        {
          text: "Принять участие в Конкурсе может:",
          noPoint: true,
        },
        {
          text: "3.1 Обучающийся последних курсов бакалавриата (3-4 курс) и специалитета (4-5 курс), а также магистратуры (1-2 курс) по направлениям подготовки государственное и муниципальное управление, менеджмент, экономика, финансы, налоги и налогобложение, политология, право и др. (далее стажер-исследователь), имеющий опыт исследовательской и проектной деятельности; со средним учебным баллом не менее 4,0, а также обладающий определенными личностными (склонность к исследовательской и проектной деятельности, коммуникативные навыки, позволяющие научно аргументировать результаты исследования и участвовать в дискуссиях и т.д.) и профессиональными (знание основ исследовательской работы, наличие навыков проектной работы, способность выступать публично и презентовать полученные результаты) компетенциями.",
          noPoint: true,
        },
        {
          text: "3.2 Научно-педагогический работник вуза, имеющий опыт педагогической деятельности и руководства научно-исследовательской работой студентов (НИРС), а также опыт проектной деятельности, сферой научных интересов которого является государственное и муниципальное управление (далее научный руководитель (трекер)). ",
          noPoint: true,
        },
      ],
    },
    {
      name: "Этапы конкурса",
      items: [
        "Прием и отбор конкурсных заявок. Прием документов осуществляется до 5 ноября 2023 года*;",
        "Отборочная форсайт-сессия. Проводится в течение недели после окончания приема заявок;",
        "Воркшоп «Методология подготовки проектных идей». Основная задача – распределение участников конкурса по проектным группам (стажер-исследователь, научный руководитель, наставник) и совместное определение целей и задач конкурсной работы (проекта). Проводится в течение недели после проведения конкурсного отбора;",
        "Теоретический блок – 3 вводные лекции. Проводится в первые 2 недели после проведения воркшопа;",
        "Работа над проектом (конкурсной работой) в связке: стажер-исследователь, научный руководитель, наставник (3 месяца);",
        "Экспертная сессия по предварительной оценке и корректировке проектов, которая проводится индивидуально с каждой проектной группой. Проводится через 2 месяца работы над проектом;",
        "Защита проектов перед конкурсной комиссией;",
        "Оценка работ и награждение 5 лучших проектных команд.",
      ],
    },
    {
      name: "Конкурсная документация",
      items: [
        {
          text: "Пакет документов стажеров-исследователей для участия в конкурсе включает в себя:",
          type: "disk",
          items: [
            "заявку;",
            "копию страниц зачетной книжки;",
            "резюме;",
            "портфолио, содержащее в себе дипломы/ сертификаты/ грамоты/ благодарственные письма за весь период обучения.",
          ],
        },
        {
          text: "Пакет документов научных руководителей для участия в конкурсе включает в себя:",
          type: "disk",
          items: [
            "заявку;",
            "портфолио, содержащее в себе дипломы/ сертификаты/ грамоты/ благодарственные письма;",
            "согласие на обработку персональных данных.",
          ],
        },
        "Пакет документов, предоставленный в неполном объеме, не допускается к участию в конкурсном отборе.",
      ],
    },
    {
      name: "Конкурсная работа (проект)",
      items: [
        "Конкурсная работа  должна быть выполнена в виде проекта, цель которого разработка нового решения предоставления услуг населению органами публичной власти на основе клиентоцентричного подхода с учетом лучших практик.",
        {
          type: "disk",
          text: "В рамках выполнения конкурсной работы должны быть рассмотрены следующие вопросы:",
          items: [
            "проведен анализ нормативно-правовых и организационно-правовых документов, регламентирующих деятельность органа публичной власти (структурного подразделения);",
            "сформирован реестр процессов предоставления услуг населению и бизнесу органом публичной власти;",
            "на основе интервьюирования сотрудников органа публичной власти и изучения клиентского пути проведен анализ процесса предоставления услуги, изучена текущая ситуация, выделена ключевая проблема;",
            "изучены лучшие практики предоставления услуги.",
          ],
        },
        "Результатом выполнения конкурсной работы должен стать проект нового решения предоставления услуг населению органами публичной власти на основе клиентоцентричного подхода с учетом лучших практик.",
      ],
    },
    {
      name: "Финал конкурса",
      items: [
        "Заключительное мероприятие (защита проектов) проводится в рамках Дня российской науки;",
        "Все участники Конкурса при соблюдении всех регламентов получают денежные премии (стажер-исследователь – 9 000,00 руб., научный руководитель – 18 000,00 руб.);",
        "Пять лучших проектов (конкурсных работ) получают денежный приз в размере 30 000,00 рублей.",
      ],
    },
    {
      name: "Критерии оценки участников и конкурсных работ (проектов)",
      items: [
        {
          text: "Оценка участников проводится по следующим критериям:",
          noPoint: true,
        },
        {
          text: "8.1. Стажера-исследователя",
          items: [
            "средний учебный балл – 4-5 баллов",
            "участие во внеучебном проекте в сфере проектной деятельности – 3 балла каждый;",
            "участие в отборочной форсайт-сессии – 5-25 баллов в зависимости от качества демонстрации следующих компетенций: командная работа, лидерство, коммуникабельность, исполнительность, новаторство, способность к проектному типу деятельности.",
          ],
          type: "disk",
          noPoint: true,
        },
        {
          text: "8.2. Научного руководителя",
          items: [
            "наличие ученой степени кандидат/доктор наук – 1 балл;",
            "наличие опыта преподавания – 1 балл;",
            "наличие опыта НИРС – 1 балл; ",
            "участие в конкурсах и грантах в сфере проектной деятельности – 3 балла каждый;",
            "прохождение стажировок в органах власти – 1 балл;",
            "участие в выполнении научно-исследовательских работ в сфере государственного и муниципального управления – 1 балл каждая; ",
            "участие в работе коллегиальных органов при органах исполнительной и представительной власти – 1 балл;",
            "руководство образовательными программами в сфере государственного и муниципального управления – 1 балл.",
          ],
          type: "disk",
          noPoint: true,
        },
        {
          text: "8.3. Экспертиза и оценка проектов (конкурсных работ) участников проводится по критериям, предварительно обсужденным на экспертной сессии;",
          noPoint: true,
        },
        {
          text: "8.4. Критерии оценки: соответствие требованиям, предъявляемым к содержанию конкурсной работы, разработанность проекта, новизна проектного решения, возможность внедрения проектного решения в органе публичной власти, качество презентации результатов проекта.",
          noPoint: true,
        },
      ],
    },
    {
      name: "Конкурсная комиссия, эксперты и жюри",
      items: [
        "Конкурсная комиссия - орган, который определяет окончательный состав участников Конкурса, а также принимает решения по всем ключевым вопросам Конкурса;",
        "В состав конкурсной комиссии входят представители органов публичной власти, вузов и коммерческого банка Центр-Инвест;",
        "Эксперты, осуществляющие содержательный контроль конкурсных работ (проектов) - специалисты, приглашаемые организаторами Конкурса для выдачи квалифицированного заключения о соответствии конкурсных работ заявленным требованиям, дающие рекомендации о возможных дальнейших развитиях проекта.",
        "Жюри – специалисты, приглашаемые организаторами Конкурса для итоговой оценки конкурсных работ (проектов).",
      ],
    },
  ],
  members: [
    "Принять участие в Конкурсе может любой преподаватель Академии вне зависимости от педагогического стажа.",
    "До участия в Конкурсе не допускаются победители предыдущего сезона.",
    "Принять участие в конкурсном отборе можно только индивидуально.",
  ],
  modals: [
    {
      title: "Общекультурная компетеность",
      text: "Совокупность общекультурных и профессиональных знаний, позволяющих определять и оценивать различные явления действительности с опорой на ценностные основания и в соответствии с культурными и эстетическими нормами и правилами поведения.",
    },
    {
      title: "Коммуникативная компетентность",
      text: "Совокупность практических коммуникативных умений, применяемых преподавателем в отношении студентов и коллег. \n\nВладение психологическими механизмами эмпатии, идентификации, адаптации, рефлексии.",
    },
    {
      title: "Педагогическая компетентность",
      text: "Совокупность теоретических и практических знаний и умений преподавателя в области построения образовательного процесса, выражающаяся в способности самостоятельно, ответственно, эффективно выполнять функции обучения, воспитания и развития будущих специалистов.",
    },
    {
      title: "Методическая компетентность",
      text: "Совокупность теоретических и практических умений, навыков, формирующих способность преподавателя изыскивать, применять, создавать различные педагогические формы, методы, технологии обучения для формирования общекультурных, общепрофессиональных, профессиональных компетенций обучающихся.",
    },
    {
      title: "Цифровая компетентность",
      text: "Совокупность теоретических и практических знаний и умений преподавателя уверенно, безопасно и эффективно определять и использовать информационные технологии в профессиональной деятельности.",
    },
    {
      title: "Управленческая компетентность",
      text: "Совокупность теоретических и практических умений преподавателя проводить педагогический анализ, ставить цели, планировать, организовывать учебную и научную деятельность студента/студенческого коллектива.",
    },
  ],
  dates: [
    "14 сентября - 23 октября",
    "1 октября - 15 ноября",
    "16 ноября - 17 декабря",
    "18 декабря - 24 января",
    "25 января - 18 февраля",
    "4 марта",
    "28 - 31 марта",
  ],
};

export const WINNERS = [
  {
    name: "Дудник Ирина Александровна",
    university: "ЮРИУ РАНХиГС",
    theme:
      "«Карьерный компас: получение высшего (среднего профессионального) образования с учетом потребностей рынка труда»",
    scientificAdviser: "к.э.н., доцент Скидан Анастасия Васильевна",
    internship: "Администрация города Азова",
  },
  {
    name: "Костенко Вячеслав Юрьевич",
    university: "ДГТУ",
    theme:
      "«Разработка онлайн сервиса «студенческий профайлер» для поддержки трудоустройства и финансовой помощи студентам от государства»",
    scientificAdviser: "к.пед.н., доцент Витченко Ольга Викторовна",
    internship: "Министерство цифрового развития, связи и массовых коммуникаций Ростовской области",
  },

  {
    name: "Кудинова Ксения Вячеславовна",
    university: "ЮРИУ РАНХиГС",
    theme: "«Создание единой электронной карты учащегося Ростовской области»",
    scientificAdviser: "д.э.н., профессор Черкасова Татьяна Павловна",
    internship: "Министерство общего и профессионального образования Ростовской области",
  },
  {
    name: "Тепикина Елена Ивановна",
    university: "РГЭУ РИНХ",
    theme: '«Региональный цифровой сервис "Налоговая грамотность"» ',
    scientificAdviser: "к.э.н., доцент Мусаелян Анжелика Капреловна ",
    internship: "Управление Федеральной налоговой службы по Ростовской области",
  },
  {
    name: "Трофимова Ирина Олеговна",
    university: "ЮФУ",
    theme:
      '«Модернизация портала "Сделаем вместе" с целью совершенствования механизмов поддержки инициативных проектов»',
    scientificAdviser: "к.э.н., доцент Колчина Оксана Александровна",
    internship: "Министерство региональной политики и массовых коммуникаций Ростовской области",
  },
];
