import styled from "styled-components";
import { colors } from "../../constants/colors";
import { sizes, font } from "../../constants/fonts";
import { borderRadius } from "../../constants/values";

export const InfoSection = styled.section`
  gap: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .info {
    display: flex;
    align-items: center;
  }

  p {
    line-height: 1.5em;
    text-align: justify;
    margin-bottom: 30px;

    span {
      color: ${colors.secondary};
      font-family: ${font.semibold};

      &.red {
        color: ${colors.red};
      }
    }
  }

  img {
    max-width: 500px;
  }
`;

export const GoalSection = styled.section`
  display: flex;
  align-items: center;
  position: relative;
  gap: 50px;
  text-align: justify;

  .desc {
    margin-bottom: 50px;
  }

  img {
    width: 500px;
  }

  @media (max-width: 1100px) {
    img {
      width: 35dvw;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    img {
      width: 300px;
    }
  }
`;

export const MembersSection = styled.section`
  ul {
    display: flex;
    flex-direction: column;
    gap: 15px;

    li {
      border-radius: ${borderRadius.big};
      border: 1px solid ${colors.black}20;
      padding: 15px 20px;
      flex: 1;

      ul {
        margin-top: 5px;
        gap: 5px;
        display: block;

        li {
          font-family: ${font.regularItalic};
          border: none;
          padding: 0;
        }
      }

      span {
        min-height: 60px;
        min-width: 60px;
        border-radius: 30px;
        border: 2px solid ${colors.primary}50;
        display: grid;
        place-items: center;
        color: ${colors.primary};
        font-family: ${font.semibold};
        font-size: ${sizes.title};
      }
    }
  }
`;

export const Member = styled.div`
  display: flex;
  gap: 50px;
  margin-bottom: 50px;
  text-align: justify;

  &.reverse {
    flex-direction: row-reverse;
  }

  .requirements {
    margin: 20px 0;

    & > ul {
      margin-top: 20px;
    }
  }

  img {
    align-self: flex-start;
    max-width: 300px;
  }

  h3 {
    color: ${colors.secondary};
    font-family: ${font.bold};
    font-size: ${sizes.middle};
    margin-bottom: 20px;
  }

  @media (max-width: 900px) {
    &,
    &.reverse {
      flex-direction: column;
      align-items: center;
    }

    img {
      align-self: center;
    }
  }
`;

export const WorkSection = styled.section`
  text-align: justify;
  margin-bottom: 50px;
  ul {
    p {
      margin-bottom: 10px;
    }
    li {
      margin-left: 15px;
      font-family: ${font.regularItalic};
    }
  }
`;

export const StagesSection = styled.section`

  p.desc {
    text-align: left;
  }

  .stage-block {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${colors.primary}20;

    &:nth-child(5) {
      border-bottom: none;
    }

    .stage {
      height: 40px;
      width: 80px;
      min-width: 80px;
      background-color: ${colors.primary};
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${colors.white};
      font-family: ${font.bold};
      text-transform: uppercase;
      border-radius: 10px;
    }

    .text-block {
      p {
        font-family: ${font.semibold};
        font-size: ${sizes.middle};
        margin-bottom: 15px;
        min-height: 40px;
        display: flex;
        align-items: center;
        line-height: 22px;
      }

      li {
        text-align: left;
        margin-top: 10px;
      }
    }
  }

  .row {
    display: flex;
    gap: 1px;
    flex: 1;

    &:not(:last-child) {
      border-bottom: 1px solid ${colors.black}10;
    }
  }

  .table-content > .row:not(:last-child) {
  }

  .row.gray {
    background-color: ${colors.black}50;
  }

  .fist-stage {
    height: 180px;
  }

  .ceil {
    background-color: ${colors.white};

    .row {
      align-self: stretch;
      & > .ceil {
        align-self: stretch;
      }
    }
  }

  .ceil,
  .caption {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .column {
    flex-direction: column;
  }

  .caption {
    background-color: ${colors.primary};
    color: ${colors.white};
    font-family: ${font.bold};
  }

  .table {
    overflow: scroll;

    .table-content {
      min-width: 660px;
    }
  }

  .desc {
    text-align: center;
    margin-top: 20px;
  }
`;

export const OrganizatorsSection = styled.section`
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;

  .desc {
    text-align: justify;
    margin-bottom: 20px;
  }

  button {
    align-self: center;
    margin-top: 30px;
  }
`;
