import React from "react";
import { Container } from "../../components/Container";

import * as S from "./styled";
import IonIcon from "@reacticons/ionicons";

import RequestTeacher from "../../assets/docs/Заявка научного руководителя.docx";
import RequestIntent from "../../assets/docs/Заявка стажера-исследователя.docx";
import ResumeDoc from "../../assets/docs/Резюме стажера-исследователя.docx";
import Doctest from "../../assets/docs/Положение о конкурсе 2023.pdf";
import RequestModal from "../../components/RequestModal";
import { Button } from "../../components/Button";

const Documents = () => {
  const [modalIsVisible, setModalIsVisible] = React.useState(false);

  return (
    <Container>
      <S.Docs>
        <h2 className="title">Положение о конкурсе</h2>
        <div className="link" style={{ marginBottom: 50 }}>
          <IonIcon name="download-outline" size="large" />
          <a href={Doctest} download={true}>
            Положение о конкурсе
          </a>
        </div>
        <h2 className="title">Шаблоны документов</h2>
        <div className="links-container">
          <div className="link">
            <IonIcon name="download-outline" size="large" />
            <a href={RequestIntent} download={true}>
              Заявка стажера-исследователя
            </a>
          </div>
          <div className="link">
            <IonIcon name="download-outline" size="large" />
            <a href={ResumeDoc} download={true}>
              Резюме стажера-исследователя
            </a>
          </div>
          <div className="link">
            <IonIcon name="download-outline" size="large" />
            <a href={RequestTeacher} download={true}>
              Заявка научного руководителя
            </a>
          </div>
        </div>
        <p className="desc" style={{ marginBottom: 50, textAlign: "justify" }}>
          <i>
            Все достижения стажера-исследователя и научного-руководителя оцениваются при наличии его документального
            подтверждения (диплом, грамота, благодарственное письмо, справка и др.)
            <br />
            Все подтверждающие документы необходимо загрузить заархивированной папкой под названием "Портфолио".
          </i>
        </p>
        <div className="button-container">
          <Button onClick={() => setModalIsVisible(true)}>Отправить заявку</Button>
        </div>
      </S.Docs>
      <RequestModal isVisible={modalIsVisible} closeModal={() => setModalIsVisible(false)} />
    </Container>
  );
};

export default Documents;
