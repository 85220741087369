import { Link } from "react-router-dom";
import { Container } from "../Container";
import * as S from "./styled";

import CILogo from "../../assets/img/CI_logo.png";
import AcademyLogo from "../../assets/img/academy_logo.png";

const Footer = () => {
  return (
    <S.Footer>
      <Container>
        <S.FooterContent>
          <div className="logo">
            <img src={AcademyLogo} alt="" />
            <div className="line"></div>
            <img className="ci" src={CILogo} alt="" />
          </div>
          <nav>
            <ul>
              <p className="title-block">О проекте</p>
              <li>
                <Link to={"/"}>О конкурсе</Link>
              </li>
              <li>
                <Link to={"/members"}>Участники</Link>
              </li>
              <li>
                <Link to={"/documents"}>Конкурсная документация</Link>
              </li>
              <li>
                <Link to={"/reglament"}>Регламент</Link>
              </li>
            </ul>
          </nav>
          <div>
            <p className="title-block">Контакты</p>
            <p>
              <b>Тел.:</b> 8 938 544-94-07
            </p>
            <br />
            <p>
              <b>Электронная почта:</b>
              <br />
              stagerium.org@yandex.ru
            </p>
          </div>
        </S.FooterContent>
      </Container>
    </S.Footer>
  );
};

export default Footer;
