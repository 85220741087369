import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { router } from "./routes";
import GlobalStyle from "./styles/globalStyles";
import Header from "./components/Header";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer";

function App() {
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [location.pathname]);

  return (
    <React.Fragment>
      <Header />
      <main>
        <Routes>
          {router.map((route, index) => (
            <Route key={index} element={route.element} path={route.path} />
          ))}
        </Routes>
      </main>
      <Footer />
      <ToastContainer />
      <GlobalStyle />
    </React.Fragment>
  );
}

export default App;
