export const font = {
  regular: "ALSHauss-Regular",
  regularItalic: "ALSHauss-RegularItalic",
  semibold: "ALSHauss-Medium",
  bold: "ALSHauss-Bold",
  extrabold: "ALSHauss-Black",
};

export const sizes = {
  normal: "16px",
  desc: "18px",
  bigDesc: "30px",
  title: "26px",
  bitTitle: "40px",
  middle: "22px",
};
