import { Container } from "../../components/Container";
import { WINNERS } from "../../constants/content";
import { WinnerItem, WinnersContainer } from "./styled";

const Winners = () => {
  return (
    <Container>
      <WinnersContainer>
        {WINNERS.map((item, index) => (
          <WinnerItem key={index}>
            <p>
              {index + 1}. <span className="name">{item.name}</span> ({item.university})
            </p>
            <p>
              Тема конкурсной работы: <i>{item.theme}</i>
            </p>
            <p>Научный руководитель – {item.scientificAdviser}</p>
            <p>Место прохождения стажировки – {item.internship}</p>
          </WinnerItem>
        ))}
        <WinnerItem></WinnerItem>
      </WinnersContainer>
    </Container>
  );
};

export default Winners;
