import { useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import { Container } from "../Container";

import * as S from "./styled";
import React from "react";

const Header = () => {
  const location = useLocation();

  const memoSpanContent = React.useMemo(() => {
    switch (location.pathname) {
      case "/documents":
        return "конкурсная документация";
      case "/members":
        return "участники проекта";
      case "/reglament":
        return "регламент";
      case "/winners":
        return "ПОБЕДИТЕЛИ КОНКУРСА 2024 ГОД (сезон 2023-2024)"
      default:
        return "о конкурсе";
    }
  }, [location]);

  return (
    <S.Header>
      <Navbar location={location.pathname} />
      <Container>
        <div className="logo-container"></div>
        <h1>
          СТАЖЕРИУМ: <span>РАЗВИВАЙ ТАЛАНТ ВО ВЛАСТИ</span>
        </h1>
        <div className="block">
          <span>{memoSpanContent}</span>
        </div>
      </Container>
    </S.Header>
  );
};

export default Header;
