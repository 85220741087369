import React from "react";
import { Container } from "../../components/Container";

import * as S from "./styled";

import FistImage from "../../assets/img/pictograms/black-pictogram-06.png";
import SecondImage from "../../assets/img/pictograms/black-pictogram-04.png";
import ThirdImage from "../../assets/img/pictograms/black-pictogram-16.png";

import TeacherFirstImage from "../../assets/img/pictograms/black-pictogram-08.png";
import TeacherSecondImage from "../../assets/img/pictograms/black-pictogram-06.png";
import TeacherThirdImage from "../../assets/img/pictograms/black-pictogram-04.png";
import TeacherFourtImage from "../../assets/img/pictograms/black-pictogram-16.png";

const Members = () => {
  return (
    <Container>
      <S.CriteriaSection>
        <h2 className="title">Критерии конкурсного отбора стажеров-исследователей</h2>
        <div className="row">
          <div className="card f-1">
            <img src={FistImage} alt="" />
            <p className="desc">средний учебный балл</p>
          </div>
          <div className="card f-2">
            <img src={SecondImage} alt="" />
            <p className="desc">количество внеучебных проектов, в которых принимал участие конкурсант</p>
          </div>
        </div>
        <div className="row">
          <div className="card f-1">
            <img src={ThirdImage} alt="" />
            <span className="desc">
              активное участие в приоритет-форсайт-сессии с демонстрацией следующих компетенций: командная работа,
              лидерство, коммуникабельность, исполнительность, новаторство, способность к проектному типу деятельности
            </span>
          </div>
        </div>
        <p className="desc">
          <b>Система оценивания:</b>
        </p>
        <p className="desc grade">средний учебный балл – 4-5 баллов;</p>
        <p className="desc grade">участие во внеучебном проекте в сфере проектной деятельности – 3 балла каждый;</p>
        <p className="desc grade">участие в приоритет-форсайт-сессии – 5-25 баллов.</p>
      </S.CriteriaSection>
      <S.CriteriaSection>
        <h2 className="title">Критерии конкурсного отбора научных руководителей (трекеров):</h2>
        <div className="row">
          <div className="card">
            <img src={TeacherFirstImage} alt="" />
            <p className="desc">наличие опыта педагогической деятельности</p>
          </div>
          <div className="card">
            <img src={TeacherSecondImage} alt="" />
            <p className="desc">наличие опыта руководства научно-исследовательской работой студентов (НИРС)</p>
          </div>
          <div className="card">
            <img src={TeacherThirdImage} alt="" />
            <p className="desc">наличие опыта участия в конкурсах и грантах в сфере проектной деятельности</p>
          </div>
        </div>
        <div className="row">
          <div className="card f-1">
            <img src={TeacherFourtImage} alt="" />
            <div className="desc">
              сфера научно-практических интересов – государственное и муниципальное управление (руководство
              образовательной программой, участие в конкурсах, прохождение стажировок, участие в работе коллегиальных
              органов исполнительной и представительной власти, публикационная активность, участие в выполнении
              научно-исследовательских работ в сфере государственного и муниципального управления, опыт работы в органах
              власти)
            </div>
          </div>
        </div>
        <p className="desc">
          <b>Система оценивания:</b>
        </p>
        <p className="desc grade">наличие ученой степени кандидат/доктор наук – 1 балл;</p>
        <p className="desc grade">наличие опыта педагогической деятельности – 1 балл;</p>
        <p className="desc grade">наличие опыта НИРС – 1 балл; </p>
        <p className="desc grade">участие в конкурсах и грантах в сфере проектной деятельности – 3 балла каждый;</p>
        <p className="desc grade">прохождение стажировок в органах власти – 1 балл;</p>
        <p className="desc grade">
          участие в выполнении научно-исследовательских работ в сфере государственного и муниципального управления – 2
          балла каждая;
        </p>
        <p className="desc grade">
          участие в работе коллегиальных органов при органах исполнительной и представительной власти – 1 балл;
        </p>
        <p className="desc grade">
          руководство образовательными программами в сфере государственного и муниципального управления – 1 балл.{" "}
        </p>
      </S.CriteriaSection>
    </Container>
  );
};

export default Members;
